<script>
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useLocalization } from '/~/composables/localization'

export default {
  name: 'travel-terms-modal',
  components: {
    BaseMdl,
  },
  props: {
    packageDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { translate } = useLocalization()

    return { translate }
  },
}
</script>

<template>
  <base-mdl
    fullscreen="mobile"
    :title="`Offer Terms ${translate('common.and')} Conditions`"
  >
    <div class="markup" v-html="packageDetails.fine_print" />
  </base-mdl>
</template>
